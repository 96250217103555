import i18n from "@/i18n";

import Vue from "vue";
import App from "./App.vue";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import router from "@/router";

import "@/plugins/globalComponents";
import "@/plugins/vue-notification";
import "@/plugins/vue-cookie";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "core-js/features/reflect/own-keys";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
/*
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
Sentry.init({
  Vue,
  dsn:
    "https://2692c3e27c2d47ba81a4dd4cd4c7909b@o326631.ingest.sentry.io/5758357",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
*/
new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount("#obifi");
