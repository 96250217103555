import Vue from 'vue';

import VueRouter from 'vue-router';

import MainLogin from '@/router/main';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [MainLogin, { path: '*', redirect: '/' }],
    scrollBehavior: (to) => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    }
});

export default router;
