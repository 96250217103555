import Vue from 'vue';

Vue.component('ObiApp', () => import('@/components/Common/ObiApp'));
Vue.component('ObiLoading', () => import('@/components/Common/ObiLoading'));

Vue.component('ObiText', () => import('@/components/UI/ObiText'));
Vue.component('ObiInput', () => import('@/components/UI/ObiInput'));
Vue.component('ObiForm', () => import('@/components/UI/ObiForm'));
Vue.component('ObiFloatingInput', () => import('@/components/UI/ObiFloatingInput'));
Vue.component('ObiButton', () => import('@/components/UI/ObiButton'));
Vue.component('ObiIcon', () => import('@/components/UI/ObiIcon'));
Vue.component('ObiSelect', () => import('@/components/UI/ObiSelect'));
Vue.component('ObiImage', () => import('@/components/UI/ObiImage'));
Vue.component('ObiCol', () => import('@/components/UI/ObiCol'));
Vue.component('ObiRow', () => import('@/components/UI/ObiRow'));
Vue.component('ObiPhoneInput', () => import('@/components/UI/ObiPhoneInput'));
Vue.component('ObiAlert', () => import('@/components/UI/ObiAlert'));
Vue.component('ObiSurveySteps', () => import('@/components/UI/ObiSurveySteps'));
Vue.component('ObiStepItem', () => import('@/components/UI/ObiStepItem'));
Vue.component('ObiCard', () => import('@/components/UI/ObiCard'));
Vue.component('ObiSurveyAnswers', () => import('@/components/UI/ObiSurveyAnswers'));
Vue.component('ObiTitle', () => import('@/components/UI/ObiTitle'));

Vue.component('Obi404', () => import('@/components/UI/Obi404'));

Vue.component('DeviceOnlineIcon', () => import('@/components/Icons/general/DeviceOnlineIcon'));
