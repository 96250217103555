import keys from "lodash/keys";
import forEach from "lodash/forEach";
import ErrorHandler from "@/libs/error-handler";

const AuthHandler = {
  handle: async (authResponse) => {
    if (process.env.NODE_ENV === "development") {
      console.info(authResponse);
    }

    if (authResponse.action == "redirect") {
      const redirectUrl = authResponse.target;
      const params = authResponse.data;

      const method = authResponse.method || "post";
      if (method === "sophos") {
        try {
          var formBody = [];
          for (var property in params) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(params[property]);
            formBody.push(encodedKey + "=" + encodedValue);
          }
          formBody = formBody.join("&");
          window.location = `${redirectUrl}/httpclient.html?${formBody}`;
        } catch (err) {
          ErrorHandler.handle(err);
        }
      } else {
        var form = document.createElement("form");
        form.setAttribute("method", method);
        form.setAttribute("action", redirectUrl);

        forEach(keys(params), (key) => {
          var hiddenField = document.createElement("input");
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("name", key);
          hiddenField.setAttribute("value", params[key]);

          form.appendChild(hiddenField);
        });

        document.body.appendChild(form);
        form.submit();
      }
    }
  },
  encodeQueryData: (data) => {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
    return ret.join("&");
  },
};

export default AuthHandler;
