import Form from "@/libs/form";

const fields = [
  "room_number",
  "birth_day",
  "birth_month",
  "birth_year",
  "departure_date",
  "email",
  "phone",
  "idNumber",
];

const rules = {
  room_number: "required",
  birth_day: "required",
  birth_month: "required",
  birth_year: "required",
  departure_date: "sometimes",
  idNumber: "sometimes",
  email: "sometimes|email",
  phone: "sometimes",
};

export default Form.create(fields).validate(rules);
