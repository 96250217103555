<template>
  <div class="container">
    <div class="mx-auto">
      <ObiLoading v-if="isLoading" />
      <div v-else class="card-body-complete">
        <div class="row">
          <div class="col-12">
            <div class="text-center connection-body">
              <div class="py-4"></div>
              <DeviceOnlineIcon class="mb-4" />
              <h5 class="fs-20 fw-400 c-primary">
                {{ $ml.get('success_title') }}
              </h5>
              <div class="c-secondary fw-400">
                {{ $ml.get('success_ready_title') }}
              </div>
              <div class="py-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash'

import ErrorHandler from '@/libs/error-handler'
import PanelPortalApi from '@/api/PanelPortalApi'
import RuleHandler from '@/libs/rule-handler'

export default {
  name: 'SuccessPage',
  components: {},
  async beforeMount() {
    if (get(this.$route.query, 'rHid')) {
      this.rhid = get(this.$route.query, 'rHid')
      await this.preparePortal()
    }
    if (get(this.$route.query, 'deviceHid')) {
      this.rhid = get(this.$route.query, 'deviceHid')
      await this.preparePortal()
    }
  },
  beforeDestroy() {},
  data() {
    return {
      isLoading: false,
      rhid: null,
      deviceHid: null,
    }
  },
  methods: {
    async preparePortal() {
      this.isLoading = true

      try {
        this.$route.query.success_page = true
        const options = await PanelPortalApi.options(this.$route.query)
        if (options.data) {
          this.rules = get(options.data, 'rules')
          this.messages = options.data.messages
        }
      } catch (err) {
        ErrorHandler.handle(err)
        this.hasError = true
        const data = get(err, 'data') || get(err, 'response.data')
        this.errorMessage = get(data, 'message')
      } finally {
        this.isLoading = false

        RuleHandler.handle(this.rules, 'login_successful', this.rhid)
      }
    },
  },
}
</script>

<style scoped>
body {
  width: 100%;
  height: 100%;
  background-color: #ffffff !important;
}
</style>
