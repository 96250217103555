<template>
  <div class="portal-app">
    <div class="error-card">
      <IconDevice404 />
      <h3 class="mt-4">
        Abonelik Hizmeti Sonlandırılmıştır
      </h3>
      <p class="mt-4">
        Destek almak için <b>0242 606 1735</b> numarasını arayabilir veya
        <b>destek@obifi.com</b> adresine e-posta gönderebilirsiniz.
      </p>

      <p class="mt-4">
        <img
          style="max-width:60px;"
          src="@/assets/images/obifi-blue.svg"
          alt="obifi logo"
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuspendedPage",
  components: {},
  async beforeMount() {},
  beforeDestroy() {},
  data() {
    return {
      isLoading: false,
      rhid: null,
    };
  },
  methods: {},
};
</script>

<style scoped>
body {
  width: 100%;
  height: 100%;
  background-color: #ffffff !important;
}
.portal-app {
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  background-size: cover !important;
}
.error-card {
  background: #ffffff;
  border-radius: 10px;
  padding: 60px;
  margin-top: 60px;
  max-width: 560px;

  @media (max-width: 375px) {
    margin-top: 0px;
  }
}

.error-card h3 {
  font-size: 22px;
  line-height: 28px;
  color: #d02e26;
  margin-top: 20px;
}

.error-card p {
  font-family: "Roboto";
  font-style: normal;
  font-size: 18px;
  line-height: 23px;
  color: #848484;
}
</style>
