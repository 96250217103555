import Form from "@/libs/form";

const fields = [
  "citizen_id",
  "firstname",
  "lastname",
  "birth_day",
  "birth_month",
  "birth_year",
  "email",
  "phone",
];

const rules = {
  citizen_id: "required",
  firstname: "sometimes",
  lastname: "sometimes",
  birth_day: "sometimes",
  birth_month: "sometimes",
  birth_year: "sometimes",
  email: "sometimes|email",
  phone: "sometimes",
};

export default Form.create(fields).validate(rules);
