import { render, staticRenderFns } from "./Temp.vue?vue&type=template&id=2e3c04e4&scoped=true&"
import script from "./Temp.vue?vue&type=script&lang=js&"
export * from "./Temp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e3c04e4",
  null
  
)

export default component.exports