<template>
	<div>
		<ObiLoading v-if="isLoading" />
		<ObiText class="ad-page p-4 vh-100" v-else>
			<span class="ad-title">{{ $ml.get("redirecting") }}</span>
			<ObiRow
				class="row w-100 d-flex mt-4 justify-content-center"
				v-if="adData"
			>
				<slider ref="slider" :options="options">
					<slideritem v-for="(item, index) in adData.images" :key="index">
						<img :src="item" style="max-width: 100%;height: 100%;" />
					</slideritem>
				</slider>
			</ObiRow>
		</ObiText>
	</div>
</template>

<script>
import { get } from "lodash";

import ErrorHandler from "@/libs/error-handler";
import PanelPortalApi from "@/api/PanelPortalApi";
import { slider, slideritem } from "vue-concise-slider";

export default {
	name: "AdPage",
	components: {
		slider,
		slideritem,
	},
	props: {
		value: {},
		size: {
			type: Number,
		},
	},
	async mounted() {
		await this.getAd();
	},
	data() {
		return {
			selectedLang: "tr",
			rhid: null,
			isLoading: false,
			hasError: false,
			errorMessage: null,
			adData: {},
			options: {
				currentPage: 0,
			},
		};
	},
	methods: {
		async getAd() {
			this.isLoading = true;

			try {
				const { data } = await PanelPortalApi.ads(this.$route.params.adId);
				this.adData = data.data;
			} catch (err) {
				ErrorHandler.handle(err);
				this.hasError = true;
				const data = get(err, "data") || get(err, "response.data");
				this.errorMessage = get(data, "message");
			} finally {
				this.isLoading = false;
				setTimeout(() => this.complete(), 5000);
			}
		},
		async complete() {
			this.isLoading = true;

			this.$router.push({
				name: "main-login",
				query: { rHid: this.$route.params.rHid },
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.ad-page {
	width: 100%;
	height: 100%;
	background-color: #0842b5;
	display: flex;
	flex-flow: column;
	align-items: center;

	.ad-title {
		font-size: 3rem;
		font-style: normal;
		font-weight: bold;
		text-align: center;
		color: #ffffff;
	}

	.divider {
		border: 1px solid #275fcd;
		width: 100% !important;

		&--footer {
			position: absolute;
			bottom: 80px;
		}
	}

	.footer {
		position: fixed;
		background-color: #31405d;
		bottom: 0px;
		padding: 10px;
		display: flex;
		align-items: center;
		gap: 20px;
		justify-content: space-around;

		@media (max-width: 375px) {
			justify-content: space-around;
			flex-direction: column;
		}

		.languages {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 20px;
		}

		&--actions {
			display: flex;
			justify-content: space-around;
			align-items: center;
			gap: 10px;

			button {
				background-color: #2f71f2 !important;
			}
		}
	}
}
</style>
