import Http from "@/libs/http";

class AuthApi extends Http {
  auth(rhid, slug, params) {
    return super.post(`portal/auth`, {
      params,
      rhid: rhid,
      provider_slug: slug,
    });
  }
  checkCode(params) {
    return super.post(`portal/check_code`, params);
  }
  confirmEmail(params) {
    return super.post(`visitor/confirm-email`, params);
  }
}

export default new AuthApi();
