<template>
  <ObiApp>
    <router-view />

    <notifications position="top center" :max="1" width="100%" />
  </ObiApp>
</template>

<script>
export default {
  name: 'App',
  components: {},
}
</script>

<style>
.bg-dark-gray {
  background-color: #1c1e21;
}

.text-muted {
  color: #848484 !important;
}

.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.bg-provider-info {
  background: var(--info-color) !important;
  border-radius: 5px;
  padding: 15px;
  display: var(--lite-display) !important;
  align-items: center;
  justify-content: center;
  border: 1px var(--info-color) solid;
  color: var(--secondary-color);
}

.bg-provider-alert {
  background: var(--alert-color) !important;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px var(--alert-color) solid;
  color: var(--secondary-color);
}

.obi-text-secondary {
  color: var(--secondary-color) !important;
}

.obi-text-primary {
  color: var(--primary-color) !important;
}
</style>
