<template>
  <div>
    <div
      class="bg-provider-info mt-4 mb-4"
      v-if="
        options &&
        options.method !== 'departureDate' &&
        $ml.get('room_number_info') &&
        appurl == null
      "
    >
      {{ $ml.get('room_number_info') }}
    </div>
    <div
      class="bg-provider-info mt-4 mb-4"
      v-if="
        options &&
        options.method === 'departureDate' &&
        $ml.get('room_number_departure_date_info') &&
        appurl == null
      "
    >
      {{ $ml.get('room_number_departure_date_info') }}
    </div>
    <div class="bg-provider-info mt-4 mb-4" v-else-if="appurl != null">
      <a :href="appurl" class="text-white">
        {{ $ml.get('download_app') }}
      </a>
    </div>
    <div class="bg-provider-alert mt-4 mb-4" v-if="errorMessage">
      {{ errorMessage }}
    </div>

    <ObiForm v-if="!isLoading && appurl == null" @submit.prevent="doLogin">
      <div class="my-2">
        <ObiInput
          v-if="
            options &&
            (!options.method ||
              options.method === 'both' ||
              options.method === 'idNumber' ||
              options.method === 'departureDate')
          "
          required
          transparent
          type="text"
          name="room_number"
          :disabled="isLoading"
          v-model="formDataRoom.room_number"
          :label="$ml.get('room_number')"
          :placeholder="$ml.get('room_number')"
        />
      </div>

      <ObiRow class="mt-2" v-if="options && options.method === 'idNumber'">
        <ObiCol col="12">
          <ObiInput
            required
            type="text"
            transparent
            :label="$ml.get('id_number')"
            v-model="formDataRoom.idNumber"
            :placeholder="$ml.get('id_number')"
          />
        </ObiCol>
      </ObiRow>

      <ObiRow class="mt-2" v-if="options && options.method === 'onylIdNumber'">
        <ObiCol col="12">
          <ObiInput
            required
            type="text"
            transparent
            :label="$ml.get('id_number')"
            v-model="formDataRoom.idNumber"
            :placeholder="$ml.get('id_number')"
          />
        </ObiCol>
      </ObiRow>

      <ObiTitle
        col="col-md-12"
        :title="$ml.get('birth_date')"
        v-if="
          !options ||
          !options.method ||
          (options.method !== 'idNumber' && options.method !== 'departureDate')
        "
      ></ObiTitle>
      <ObiRow
        class="mt-2"
        v-if="
          !options ||
          !options.method ||
          (options.method !== 'idNumber' && options.method !== 'departureDate')
        "
      >
        <ObiCol col="4">
          <ObiInput
            required
            type="number"
            maxlength="2"
            transparent
            :label="$ml.get('birth_day')"
            v-model="formDataRoom.birth_day"
            :placeholder="$ml.get('birth_day')"
          />
        </ObiCol>
        <ObiCol col="4">
          <ObiInput
            required
            type="number"
            maxlength="2"
            transparent
            :label="$ml.get('birth_month')"
            v-model="formDataRoom.birth_month"
            :placeholder="$ml.get('birth_month')"
          />
        </ObiCol>
        <ObiCol col="4">
          <ObiInput
            required
            type="number"
            maxlength="4"
            transparent
            :label="$ml.get('birth_year')"
            v-model="formDataRoom.birth_year"
            :placeholder="$ml.get('birth_year')"
          />
        </ObiCol>
      </ObiRow>

      <ObiTitle
        col="col-md-12"
        :title="$ml.get('departure_date')"
        v-if="options && options.method && options.method === 'departureDate'"
      ></ObiTitle>
      <ObiRow
        class="mt-2"
        v-if="options && options.method && options.method === 'departureDate'"
      >
        <ObiCol col="12">
          <ObiInput
            required
            type="date"
            transparent
            v-model="formDataRoom.departure_date"
            :placeholder="$ml.get('departure_date')"
          />
        </ObiCol>
      </ObiRow>

      <div class="my-2" v-if="options && options.collect_email">
        <ObiInput
          transparent
          type="email"
          name="email"
          :disabled="isLoading"
          v-model="formDataRoom.email"
          :label="$ml.get('email')"
          :placeholder="$ml.get('email')"
          :required="this.$props.options.email_required"
        />
      </div>

      <div class="my-2" v-if="options && options.collect_phone">
        <ObiInput
          transparent
          type="text"
          name="phone"
          :disabled="isLoading"
          v-model="formDataRoom.phone"
          :label="$ml.get('phone')"
          :placeholder="$ml.get('phone')"
          :required="this.$props.options.phone_required"
        />
      </div>
      <div class="my-2" v-if="agreements && agreements.kvkk">
        <b-form-checkbox
          :value="true"
          :unchecked-value="false"
          v-model="kvkk_aggrement"
        >
          <small style="color: #848484;">
            <a href="#" @click="$emit('showAggrement')">
              {{ $ml.get('kvkk_policy_title') }}
            </a>
          </small>
        </b-form-checkbox>
      </div>
      <div class="my-2 display-lite">
        <b-form-checkbox
          :value="true"
          :unchecked-value="false"
          v-model="general_aggrement"
        >
          <small style="color: #848484;">
            {{ $ml.get('general_policy_title') }}
          </small>
        </b-form-checkbox>
      </div>
      <ObiRow>
        <ObiCol col="12">
          <ObiButton
            block
            :disabled="agreements && agreements.kvkk && !kvkk_aggrement"
            type="submit"
            :loading="isLoading"
            :text="$ml.get('connect_now')"
          />
        </ObiCol>
      </ObiRow>
    </ObiForm>
    <ObiForm v-else-if="!isLoading && appurl != null">
      <a :href="appurl">
        <img
          v-if="appurl.indexOf('apple.com') > -1"
          style="width: 100%;"
          src="@/assets/images/app_store_icon.png"
          alt="app download"
        />
        <img
          v-else
          style="width: 100%;"
          src="@/assets/images/google_play_logo.png"
          alt="app download"
        />
      </a>
    </ObiForm>
    <ObiLoading v-else />
  </div>
</template>

<script>
import Notify from '@/libs/notify'
import { get } from 'lodash'
import AuthHandler from '@/libs/auth-handler'
import AuthApi from '@/api/AuthApi'

import formDataRoom from '@/components/login-components/FormData/form-data-room'

export default {
  props: {
    options: {
      type: [Object],
    },
    rhid: {
      type: String,
      required: true,
    },
    paid: {
      type: Object,
      required: false,
    },
    agreements: {
      type: [Object],
    },
    appurl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      slug: 'room-number',
      days: [],
      months: [],
      years: [],
      formDataRoom,
      errorMessage: null,
      isLoading: false,
      kvkk_aggrement: false,
      general_aggrement: false,
    }
  },
  beforeMount() {
    this.setYears()
  },
  mounted() {
    if (
      this.$props.options &&
      this.$props.options.method &&
      this.$props.options.method === 'idNumber'
    ) {
      this.formDataRoom._rules.idNumber = 'required'
      this.formDataRoom._rules.birth_day = 'sometimes'
      this.formDataRoom._rules.birth_month = 'sometimes'
      this.formDataRoom._rules.birth_year = 'sometimes'
    } else {
      this.formDataRoom._rules.idNumber = 'sometimes'
      this.formDataRoom._rules.birth_day = 'required'
      this.formDataRoom._rules.birth_month = 'required'
      this.formDataRoom._rules.birth_year = 'required'
    }
  },
  methods: {
    setYears() {
      const startYear = 1930
      var lastYear = new Date().getFullYear()

      for (lastYear; lastYear >= startYear; lastYear--) {
        this.years.push({ label: lastYear, value: lastYear })
      }

      for (var day = 1; day <= 31; day++) {
        this.days.push({ label: day, value: day })
      }

      for (var month = 1; month <= 12; month++) {
        this.months.push({ label: month, value: month })
      }
    },
    async doLogin() {
      if (!this.formDataRoom.isValid({ disableDirtyCheck: true })) return

      this.isLoading = true
      this.errorMessage = null

      try {
        let params = this.formDataRoom.toObject()
        if (this.$props.paid) {
          params.total_paid_day = this.$props.paid.total_day
        }
        const response = await AuthApi.auth(this.$props.rhid, this.slug, params)

        if (response.data.success) {
          Notify.success(this.$ml.get('authorized'))
          if (
            response.data.data &&
            response.data.data.action === 'buy_package'
          ) {
            this.$emit(
              'showPackage',
              response.data.data,
              this.$props.rhid,
              this.slug,
              params,
            )
          } else {
            AuthHandler.handle(response.data.data)
          }
        } else {
          //ErrorHandler.handle(response.data);
          const data = get(response, 'data') || get(response, 'response.data')
          this.errorMessage = get(data, 'message')
        }
      } catch (err) {
        //ErrorHandler.handle(err);
        const data = get(err, 'data') || get(err, 'response.data')
        this.errorMessage = get(data, 'message')
        this.$emit('errorCode', get(data, 'error_code'))
      } finally {
        this.isLoading = false

        this.$emit('end')
      }
    },
  },
}
</script>

<style scoped>
.display-lite {
  display: var(--lite-display) !important;
}
</style>
