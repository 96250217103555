<template>
  <div>
    <div class="bg-provider-info mt-4 mb-4" v-if="$ml.get('citizen_id_info')">
      {{ $ml.get('citizen_id_info') }}
    </div>
    <div class="bg-provider-alert mt-4 mb-4" v-if="errorMessage">
      {{ errorMessage }}
    </div>

    <ObiForm v-if="!isLoading" @submit.prevent="doLogin">
      <div
        class="my-4"
        v-if="
          !options ||
          (options.require_security_code && options.security_code != null)
        "
      >
        <ObiInput
          required
          transparent
          type="text"
          name="security_code"
          :disabled="isLoading"
          v-model="security_code"
          label="$ml.get('citizen_id')"
          :placeholder="$ml.get('citizen_id')"
        />
      </div>

      <div class="my-4">
        <ObiInput
          required
          transparent
          type="text"
          name="citizen_id"
          :disabled="isLoading"
          v-model="formDataCitizenID.citizen_id"
          :label="$ml.get('citizen_id')"
          :placeholder="$ml.get('citizen_id')"
        />
      </div>

      <div class="my-4" v-if="!options || options.provider_type !== 'local'">
        <ObiInput
          required
          transparent
          type="text"
          name="firstname"
          :disabled="isLoading"
          v-model="formDataCitizenID.firstname"
          :label="$ml.get('firstname')"
          :placeholder="$ml.get('firstname')"
        />
      </div>
      <div class="my-4" v-if="!options || options.provider_type !== 'local'">
        <ObiInput
          required
          transparent
          type="text"
          name="lastname"
          :disabled="isLoading"
          v-model="formDataCitizenID.lastname"
          :label="$ml.get('lastname')"
          :placeholder="$ml.get('lastname')"
        />
      </div>
      <div
        v-if="
          options && (!options.method || options.method !== 'only_citizen_id')
        "
        class="my-4"
      >
        <ObiTitle col="col-md-12" :title="$ml.get('birth_date')"></ObiTitle>
        <ObiRow class="mt-2">
          <ObiCol col="4">
            <ObiInput
              required
              maxlength="2"
              transparent
              :label="$ml.get('birth_day')"
              v-model="formDataCitizenID.birth_day"
              type="number"
              :placeholder="$ml.get('birth_day')"
            />
          </ObiCol>
          <ObiCol col="4">
            <ObiInput
              required
              maxlength="2"
              transparent
              :label="$ml.get('birth_month')"
              v-model="formDataCitizenID.birth_month"
              type="number"
              :placeholder="$ml.get('birth_month')"
            />
          </ObiCol>
          <ObiCol col="4">
            <ObiInput
              required
              maxlength="4"
              transparent
              :label="$ml.get('birth_year')"
              v-model="formDataCitizenID.birth_year"
              type="number"
              :placeholder="$ml.get('birth_year')"
            />
          </ObiCol>
        </ObiRow>
      </div>

      <div class="my-4" v-if="options && options.collect_email">
        <ObiInput
          transparent
          type="email"
          name="email"
          :disabled="isLoading"
          v-model="formDataCitizenID.email"
          :label="$ml.get('email')"
          :placeholder="$ml.get('email')"
          :required="this.$props.options.email_required"
        />
      </div>

      <div class="my-4" v-if="options && options.collect_phone">
        <ObiInput
          transparent
          type="text"
          name="phone"
          :disabled="isLoading"
          v-model="formDataCitizenID.phone"
          :label="$ml.get('phone')"
          :placeholder="$ml.get('phone')"
          :required="this.$props.options.phone_required"
        />
      </div>

      <div class="my-4" v-if="agreements && agreements.kvkk">
        <b-form-checkbox
          :value="true"
          :unchecked-value="false"
          v-model="kvkk_aggrement"
        >
          <small style="color: #848484;">
            <a href="#" @click="$emit('showAggrement')">
              {{ $ml.get('kvkk_policy_title') }}
            </a>
          </small>
        </b-form-checkbox>
      </div>
      <div class="my-4" v-if="false">
        <b-form-checkbox
          :value="true"
          :unchecked-value="false"
          v-model="general_aggrement"
        >
          <small style="color: #848484;">
            {{ $ml.get('general_policy_title') }}
          </small>
        </b-form-checkbox>
      </div>

      <div class="my-4">
        <ObiButton
          block
          :disabled="agreements && agreements.kvkk && !kvkk_aggrement"
          type="submit"
          :loading="isLoading"
          :text="$ml.get('connect_now')"
        />
      </div>
    </ObiForm>
    <ObiLoading v-else />
  </div>
</template>

<script>
import { get } from 'lodash'
import Notify from '@/libs/notify'
import AuthHandler from '@/libs/auth-handler'
import AuthApi from '@/api/AuthApi'

import formDataCitizenID from '@/components/login-components/FormData/form-data-citizen-id'

export default {
  props: {
    rhid: {
      type: String,
      required: true,
    },
    options: {
      type: [Object],
    },
    agreements: {
      type: [Object],
    },
  },
  data() {
    return {
      slug: 'citizen-id',
      formDataCitizenID,
      isLoading: false,
      errorMessage: null,
      days: [],
      months: [],
      years: [],
      kvkk_aggrement: false,
      general_aggrement: false,
    }
  },
  beforeMount() {
    this.setYears()
  },
  mounted() {
    if (
      this.$props.options &&
      this.$props.options.collect_email &&
      this.$props.options.email_required
    ) {
      this.formDataCitizenID._rules.email = 'required|email'
    }
    if (
      this.$props.options &&
      this.$props.options.collect_phone &&
      this.$props.options.phone_required
    ) {
      this.formDataCitizenID._rules.phone = 'required'
    }
  },
  methods: {
    setYears() {
      const startYear = 1930
      var lastYear = new Date().getFullYear()

      for (lastYear; lastYear >= startYear; lastYear--) {
        this.years.push({ label: lastYear, value: lastYear })
      }

      for (var day = 1; day <= 31; day++) {
        this.days.push({ label: day, value: day })
      }

      for (var month = 1; month <= 12; month++) {
        this.months.push({ label: month, value: month })
      }
    },
    async doLogin() {
      if (!this.formDataCitizenID.isValid({ disableDirtyCheck: true })) return

      this.isLoading = true
      this.errorMessage = null

      try {
        const response = await AuthApi.auth(
          this.$props.rhid,
          this.slug,
          this.formDataCitizenID.toObject(),
        )

        if (response.data.success) {
          Notify.success(this.$ml.get('authorized'))
          AuthHandler.handle(response.data.data)
        } else {
          //ErrorHandler.handle(response.data);

          const data = get(response, 'data') || get(response, 'response.data')
          this.errorMessage = get(data, 'message')
        }
      } catch (err) {
        //ErrorHandler.handle(err);
        const data = get(err, 'data') || get(err, 'response.data')
        this.errorMessage = get(data, 'message')
      } finally {
        this.isLoading = false

        this.$emit('end')
      }
    },
  },
}
</script>

<style scoped></style>
