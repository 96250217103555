import Vue from "vue";

import get from "lodash/get";
import map from "lodash/map";
import keys from "lodash/keys";
import forEach from "lodash/forEach";

const ErrorHandler = {
  handle: (e) => {
    if (process.env.NODE_ENV === "development") {
      console.error(e);
    }

    const opts = {
      type: "error",
      duration: 5000,
    };

    if (get(e, "data") || get(e, "response.data")) {
      const data = get(e, "data") || get(e, "response.data");
      opts.title = get(data, "message");
      const errors = get(data, "errors");
      const errorKeys = keys(errors);

      if (errorKeys.length > 0) {
        opts.text = `<ul class="list-unstyled mt-2">
                    ${map(errorKeys, (key) => `<li>${errors[key]}</li>`).join(
                      ""
                    )}
                </ul>`;
      } else {
        opts.text = opts.title;
        opts.title = this.$ml.get("an_error_occured");
      }
    } else {
      opts.title = this.$ml.get("an_error_occured");
      opts.text = e.message;
    }

    Vue.notify(opts);
  },

  handleValidation(e, formValidate) {
    if (e.status !== 422) return;

    const errors = get(e, "data.errors");
    const errorKeys = keys(errors);

    forEach(errorKeys, (key) => {
      formValidate[key] = get(errors, `${key}[0]`);
    });
  },
};

export default ErrorHandler;
