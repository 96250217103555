<template>
  <div>
    <div
      class="bg-provider-info mt-4 mb-4"
      v-if="state === 'send_code' && $ml.get('sms_info')"
    >
      {{ $ml.get('sms_info') }}
    </div>
    <div class="bg-provider-info mt-4 mb-4" v-if="state === 'confirm'">
      {{ $ml.get('sms_confirm_info') }}
    </div>

    <div class="bg-provider-alert mt-4 mb-4" v-if="errorMessage">
      {{ errorMessage }}
    </div>

    <ObiForm
      v-if="state === 'send_code' && !isLoading"
      @submit.prevent="sendSMSCode"
    >
      <div class="my-4">
        <ObiInput
          transparent
          type="number"
          name="phone"
          :disabled="isLoading"
          v-model="formDataSMS.phone"
          :label="$ml.get('phone')"
          :placeholder="$ml.get('phone')"
        />
      </div>
      <div class="my-4">
        <ObiInput
          transparent
          type="text"
          name="firstname"
          :disabled="isLoading"
          v-model="formDataSMS.firstname"
          :label="$ml.get('firstname')"
          :placeholder="$ml.get('firstname')"
        />
      </div>
      <div class="my-4">
        <ObiInput
          transparent
          type="text"
          name="la"
          :disabled="isLoading"
          v-model="formDataSMS.lastname"
          :label="$ml.get('lastname')"
          :placeholder="$ml.get('lastname')"
        />
      </div>

      <div class="my-4" v-if="options && options.collect_email">
        <ObiInput
          transparent
          type="email"
          name="email"
          :disabled="isLoading"
          v-model="formDataSMS.email"
          :label="$ml.get('email')"
          :placeholder="$ml.get('email')"
          :required="this.$props.options.email_required"
        />
      </div>
      <div class="my-4" v-if="agreements && agreements.kvkk">
        <b-form-checkbox
          :value="true"
          :unchecked-value="false"
          v-model="kvkk_aggrement"
        >
          <small style="color: #848484;">
            <a href="#" @click="$emit('showAggrement')">
              {{ $ml.get('kvkk_policy_title') }}
            </a>
          </small>
        </b-form-checkbox>
      </div>
      <div class="my-4" v-if="false">
        <b-form-checkbox
          :value="true"
          :unchecked-value="false"
          v-model="general_aggrement"
        >
          <small style="color: #848484;">
            {{ $ml.get('general_policy_title') }}
          </small>
        </b-form-checkbox>
      </div>
      <div class="my-4">
        <ObiButton
          block
          :disabled="agreements && agreements.kvkk && !kvkk_aggrement"
          type="submit"
          :loading="isLoading"
          :text="$ml.get('send_code')"
        />
      </div>
    </ObiForm>
    <ObiForm v-if="state === 'confirm' && !isLoading" @submit.prevent="doLogin">
      <div class="my-4">
        <ObiInput
          transparent
          type="number"
          name="code"
          :disabled="isLoading"
          v-model="formDataSMS.code"
          :label="$ml.get('sms_code')"
          :placeholder="$ml.get('sms_code')"
          required
        />
      </div>
      <div class="my-4">
        <ObiCol col="12">
          <ObiButton
            block
            type="submit"
            :loading="isLoading"
            :text="$ml.get('connect_now')"
          />
        </ObiCol>
      </div>
    </ObiForm>
    <ObiLoading v-if="isLoading" />
  </div>
</template>

<script>
import { get } from 'lodash'
import Notify from '@/libs/notify'
import ErrorHandler from '@/libs/error-handler'
import AuthHandler from '@/libs/auth-handler'
import SMSApi from '@/api/SMSApi'
import AuthApi from '@/api/AuthApi'

import formDataSMS from '@/components/login-components/FormData/form-data-sms'

export default {
  props: {
    options: {
      type: [Object],
    },
    agreements: {
      type: [Object],
    },
    rhid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      slug: 'sms',
      state: 'send_code',
      formDataSMS,
      isLoading: false,
      kvkk_aggrement: false,
      general_aggrement: false,
      errorMessage: null,
    }
  },
  mounted() {
    if (
      this.$props.options &&
      this.$props.options.collect_email &&
      this.$props.options.email_required
    ) {
      this.formDataSMS._rules.email = 'required|email'
    }
  },
  methods: {
    async sendSMSCode() {
      this.formDataSMS.rhid = this.$props.rhid
      if (!this.formDataSMS.isValid({ disableDirtyCheck: true })) return

      this.isLoading = true
      this.errorMessage = null

      try {
        const response = await SMSApi.send(this.formDataSMS.toObject())

        if (response.data.success) {
          Notify.success(this.$ml.get('sms_sent'))
          this.state = 'confirm'
        } else {
          ErrorHandler.handle(response.data)
        }
      } catch (err) {
        //ErrorHandler.handle(err);
        const data = get(err, 'data') || get(err, 'response.data')
        this.errorMessage = get(data, 'message')
      } finally {
        this.isLoading = false

        this.$emit('end')
      }
    },
    async doLogin() {
      if (!this.formDataSMS.isValid({ disableDirtyCheck: true })) return

      this.isLoading = true
      this.errorMessage = null

      try {
        const response = await AuthApi.auth(
          this.$props.rhid,
          this.slug,
          this.formDataSMS.toObject(),
        )

        if (response.data.success) {
          Notify.success(this.$ml.get('authorized'))
          AuthHandler.handle(response.data.data)
        } else {
          //ErrorHandler.handle(response.data);
          const data = get(response, 'data') || get(response, 'response.data')
          this.errorMessage = get(data, 'message')
        }
      } catch (err) {
        //ErrorHandler.handle(err);
        const data = get(err, 'data') || get(err, 'response.data')
        this.errorMessage = get(data, 'message')
      } finally {
        this.isLoading = false

        this.$emit('end')
      }
    },
  },
}
</script>

<style scoped></style>
