import Vue from "vue";
import { MLInstaller, MLCreate, MLanguage } from "vue-multilanguage";

Vue.use(MLInstaller);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const languages = [];
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      languages.push(new MLanguage(locale).create(locales(key)));
    }
  });

  return languages;
}

let lang = "en";
try {
  lang = navigator.language || navigator.userLanguage;
  lang = navigator.language.split("-")[0];
} catch (error) {
  console.error(error);
}
const availableLangs = ["ar", "tr", "en", "ru", "de"];
if (availableLangs.indexOf(lang) < 0) {
  lang = "en";
}
export default new MLCreate({
  initial: lang,
  languages: loadLocaleMessages(),
});
