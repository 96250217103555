<template>
  <div>
    <div class="bg-provider-info mt-4 mb-4" v-if="$ml.get('username_id_info')">
      {{ $ml.get('username_id_info') }}
    </div>

    <ObiForm v-if="!isLoading" @submit.prevent="doLogin">
      <div class="my-4">
        <ObiInput
          required
          transparent
          type="text"
          name="username"
          :disabled="isLoading"
          v-model="formDataUsername.username"
          :label="$ml.get('username')"
          :placeholder="$ml.get('username')"
        />
      </div>
      <div class="my-4">
        <ObiInput
          required
          transparent
          type="password"
          name="firstname"
          :disabled="isLoading"
          v-model="formDataUsername.password"
          :label="$ml.get('password')"
          :placeholder="$ml.get('password')"
        />
      </div>

      <div class="my-4" v-if="options && options.collect_email">
        <ObiInput
          transparent
          type="email"
          name="email"
          :disabled="isLoading"
          v-model="formDataUsername.email"
          :label="$ml.get('email')"
          :placeholder="$ml.get('email')"
          :required="this.$props.options.email_required"
        />
      </div>

      <div class="my-4" v-if="options && options.collect_phone">
        <ObiInput
          transparent
          type="text"
          name="phone"
          :disabled="isLoading"
          v-model="formDataUsername.phone"
          :label="$ml.get('phone')"
          :placeholder="$ml.get('phone')"
          :required="this.$props.options.phone_required"
        />
      </div>

      <div class="my-4" v-if="agreements && agreements.kvkk">
        <b-form-checkbox
          :value="true"
          :unchecked-value="false"
          v-model="kvkk_aggrement"
        >
          <small style="color: #848484;">
            <a href="#" @click="$emit('showAggrement')">
              {{ $ml.get('kvkk_policy_title') }}
            </a>
          </small>
        </b-form-checkbox>
      </div>
      <div class="my-4" v-if="false">
        <b-form-checkbox
          :value="true"
          :unchecked-value="false"
          v-model="general_aggrement"
        >
          <small style="color: #848484;">
            {{ $ml.get('general_policy_title') }}
          </small>
        </b-form-checkbox>
      </div>

      <div class="my-4">
        <ObiButton
          block
          :disabled="agreements && agreements.kvkk && !kvkk_aggrement"
          type="submit"
          :loading="isLoading"
          :text="$ml.get('connect_now')"
        />
      </div>
    </ObiForm>
    <ObiLoading v-else />
  </div>
</template>

<script>
import Notify from '@/libs/notify'
import ErrorHandler from '@/libs/error-handler'
import AuthHandler from '@/libs/auth-handler'
import AuthApi from '@/api/AuthApi'
import { get } from 'lodash'
import formDataUsername from '@/components/login-components/FormData/form-data-username'

export default {
  props: {
    options: {
      type: [Object],
    },
    rhid: {
      type: String,
      required: true,
    },
    agreements: {
      type: [Object],
    },
  },
  data() {
    return {
      slug: 'username',
      formDataUsername,
      isLoading: false,
      days: [],
      months: [],
      years: [],
      kvkk_aggrement: false,
      general_aggrement: false,
    }
  },
  beforeMount() {
    this.setYears()
  },
  mounted() {
    if (
      this.$props.options &&
      this.$props.options.collect_email &&
      this.$props.options.email_required
    ) {
      this.formDataUsername._rules.email = 'required|email'
    }
    if (
      this.$props.options &&
      this.$props.options.collect_phone &&
      this.$props.options.phone_required
    ) {
      this.formDataUsername._rules.phone = 'required'
    }
  },
  methods: {
    setYears() {
      const startYear = 1930
      var lastYear = new Date().getFullYear()

      for (lastYear; lastYear >= startYear; lastYear--) {
        this.years.push({ label: lastYear, value: lastYear })
      }

      for (var day = 1; day <= 31; day++) {
        this.days.push({ label: day, value: day })
      }

      for (var month = 1; month <= 12; month++) {
        this.months.push({ label: month, value: month })
      }
    },
    async doLogin() {
      if (!this.formDataUsername.isValid({ disableDirtyCheck: true })) return

      this.isLoading = true

      try {
        const response = await AuthApi.auth(
          this.$props.rhid,
          this.slug,
          this.formDataUsername.toObject(),
        )

        if (response.data.success) {
          Notify.success(this.$ml.get('authorized'))
          AuthHandler.handle(response.data.data)
        } else {
          ErrorHandler.handle(response.data)
        }
      } catch (err) {
        //ErrorHandler.handle(err);
        const data = get(err, 'data') || get(err, 'response.data')
        this.errorMessage = get(data, 'message')
        this.$emit('errorCode', get(data, 'error_code'))
      } finally {
        this.isLoading = false

        this.$emit('end')
      }
    },
  },
}
</script>

<style scoped></style>
