import router from "@/router";

const RuleHandler = {
	handle: async (rules, currentState, rHid = null) => {
		if (process.env.NODE_ENV === "development") {
			console.info(rules);
			console.info(currentState);
		}

		if (!rules) {
			return;
		}

		const matchedRules = rules.filter((rule) => rule.state === currentState);
		if (matchedRules && matchedRules.length > 0) {
			const currentRule = matchedRules[0];

			if (currentRule) {
				if (currentRule.action === "redirect_to_url") {
					await RuleHandler.redirectToUrl(currentRule.target_url);
				} else if (currentRule.action === "redirect_to_survey") {
					await RuleHandler.redirectToSurvey(currentRule.survey_id, rHid);
				} else if (currentRule.action === "show_ad") {
					await RuleHandler.redirectToAd(currentRule.ad_id, rHid);
				}
			}
		}
	},
	redirectToUrl: async (targetUrl) => {
		window.location = targetUrl;
	},
	redirectToSurvey: async (surveyId, rHid) => {
		await router.push({
			name: "survey-page",
			params: { surveyId: surveyId, rHid: rHid },
		});
	},
	redirectToAd: async (adId, rHid) => {
		await router.push({ name: "ads-page", params: { adId: adId, rHid: rHid } });
	},
};

export default RuleHandler;
