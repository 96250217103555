import Http from "@/libs/http";

class PanelPortalApi extends Http {
	options(params) {
		return super.get(`portal/options`, { params });
	}

	show(id, params) {
		return super.get(`portal/${id}`, { params });
	}

	survey(surveyId) {
		return super.get(`portal/survey/${surveyId}`);
	}

	ads(adId) {
		return super.get(`portal/ads/${adId}`);
	}

	collect(surveyId, rhid, questions) {
		return super.put(`portal/survey/${surveyId}/${rhid}`, { questions }, null);
	}
}

export default new PanelPortalApi();
