import Axios from "axios";
import get from "lodash/get";
import set from "lodash/set";
let createdClient = null;
var VueCookie = require("vue-cookie");

const createClient = (contentType) => {
  if (createdClient && contentType === null) return createdClient;

  const client = Axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    transformRequest: [
      (data, headers) => {
        set(headers, "Content-Type", "application/json");

        return JSON.stringify(data);
      },
    ],
  });
  client.interceptors.request.use(
    async (config) => {
      let tenantId = get(config.params, "tenant");

      if (window.location.hostname.indexOf("wifibulut.com") >= 0) {
        tenantId = "wifibulut";
      } else if (window.location.hostname.indexOf("nfr-captive") >= 0) {
        tenantId = "nfr";
      } else if (window.location.hostname.indexOf("quedra.com") >= 0) {
        tenantId = "quedra";
      } else if (!tenantId) {
        tenantId = VueCookie.get("tenant");
        if (!tenantId) {
          tenantId = "panel";
        }
      }
      VueCookie.set("tenant", tenantId, 1);

      config.headers["X-Tenant"] = tenantId;
      config.headers["Content-Type"] = "application/json";

      return config;
    },
    (error) => Promise.reject(error)
  );

  client.defaults.timeout = 60000 * 3;
  client.defaults.withCredentials = true;

  createdClient = client;

  return client;
};

export default createClient;
