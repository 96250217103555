<template>
  <div>
    <ObiLoading v-if="isLoading" />
    <ObiText class="survey-page p-4 vh-100" v-else>
      <ObiSurveySteps
        :size="questions && questions.length"
        :current="currentStep"
        @change="stepChanged"
      ></ObiSurveySteps>

      <ObiRow class="row w-100 d-flex mt-4 justify-content-center">
        <ObiText class="question-title text-center">
          {{ currentQuestion.title }}
        </ObiText>
      </ObiRow>

      <ObiText class="divider mt-4" />

      <ObiSurveyAnswers
        v-if="currentQuestion && currentQuestion.answer_type !== 'text'"
        @change="answerChanged"
        class="mt-4"
        :language="selectedLang"
        :selected="currentQuestion.selectedAnswer"
        :items="currentQuestion.answers"
        :type="currentQuestion.type"
      />

      <ObiText class="mt-2 w-100" v-else>
        <ObiTitle :title="$ml.get('your_message')" color="white" />
        <textarea
          rows="8"
          class="mt-2 w-100"
          :placeholder="$ml.get('your_message')"
          v-model="currentQuestion.answer_text"
        ></textarea>
      </ObiText>

      <ObiText class="footer w-100">
        <ObiText class="languages">
          <ObiText
            v-for="(language, index) in languages"
            :key="index"
            @click="changeLanguage(language.iso)"
          >
            <img
              style="max-width:1rem;"
              :src="`@/assets/images/flags/${language.iso}.svg`"
              alt="Türkçe"
            />
          </ObiText>
        </ObiText>
        <ObiText class="footer--actions mt-2">
          <ObiButton
            size="lg"
            :text="$ml.get('prev_question')"
            :disabled="currentStep <= 1"
            @click="prevQuestion"
          />
          <ObiButton
            size="lg"
            :text="$ml.get('next_question')"
            v-if="questions.length > currentStep"
            :disabled="
              currentQuestion && currentQuestion.selectedAnswer === null
            "
            @click="nextQuestion"
          />
          <ObiButton
            size="lg"
            :text="$ml.get('complete_survey')"
            v-if="questions.length === currentStep"
            @click="completeSurvey"
          />
        </ObiText>
      </ObiText>
    </ObiText>
  </div>
</template>

<script>
import { get } from "lodash";

import ErrorHandler from "@/libs/error-handler";
import PanelPortalApi from "@/api/PanelPortalApi";

export default {
  name: "SurveyPage",
  components: {},
  props: {
    value: {},
    size: {
      type: Number,
    },
  },
  computed: {
    currentQuestion() {
      let question = null;
      if (this.questions) {
        question = this.questions[this.currentStep - 1];

        if (question.answer_type === "text") {
          question.answer_text = null;
        }
        if (question && question.label[this.selectedLang]) {
          question.title = question.label[this.selectedLang];
        }
      }
      return question;
    },
  },
  async mounted() {
    await this.getSurvey();
  },
  data() {
    return {
      selectedLang: "tr",
      rhid: null,
      isLoading: false,
      hasError: false,
      errorMessage: null,
      languages: [],
      currentStep: 1,
      questions: [],
    };
  },
  methods: {
    async getSurvey() {
      this.isLoading = true;

      try {
        const { data } = await PanelPortalApi.survey(
          this.$route.params.surveyId
        );
        this.questions = data.data.map((question) => {
          question.selectedAnswer = null;
          return question;
        });
      } catch (err) {
        ErrorHandler.handle(err);
        this.hasError = true;
        const data = get(err, "data") || get(err, "response.data");
        this.errorMessage = get(data, "message");
      } finally {
        this.isLoading = false;
      }
    },
    async stepChanged(newStep) {
      this.currentStep = newStep;
    },
    async answerChanged(newAnswer) {
      this.currentQuestion.selectedAnswer = newAnswer;
    },
    async nextQuestion() {
      this.languages = [];
      if (this.questions.length > this.currentStep) {
        this.currentStep += 1;
      }
    },
    async prevQuestion() {
      this.languages = [];
      this.currentStep -= 1;
    },
    async changeLanguage(iso) {
      this.$ml.change(iso);
      this.selectedLang = iso;
    },
    async completeSurvey() {
      this.isLoading = true;

      try {
        await PanelPortalApi.collect(
          this.$route.params.surveyId,
          this.$route.params.rHid,
          this.questions
        );

        this.$router.push({
          name: "main-login",
          query: { rHid: this.$route.params.rHid },
        });
      } catch (err) {
        ErrorHandler.handle(err);
        this.hasError = true;
        const data = get(err, "data") || get(err, "response.data");
        this.errorMessage = get(data, "message");
      } finally {
        this.isLoading = false;
      }
    },
    async changeAvailableLanguages() {
      this.languages = [];
      Object.keys(this.currentQuestion.label).forEach((lang) => {
        this.languages.push({ iso: lang });
      });
    },
  },
  watch: {
    selectedLang: {
      deep: true,
      handler: function() {
        this.changeAvailableLanguages();
      },
    },
    questions: {
      deep: true,
      handler: function() {
        this.changeAvailableLanguages();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.survey-page {
  width: 100%;
  height: 100%;
  background-color: #0842b5;
  display: flex;
  flex-flow: column;
  align-items: center;

  .question-title {
    font-size: 2rem;
    color: #fff;
    font-style: normal;
    font-weight: bold;
    line-height: 3rem;
    text-align: center;
    color: #ffffff;
  }

  .divider {
    border: 1px solid #275fcd;
    width: 100% !important;

    &--footer {
      position: absolute;
      bottom: 80px;
    }
  }

  .footer {
    position: fixed;
    background-color: #31405d;
    bottom: 0px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-around;

    @media (max-width: 375px) {
      justify-content: space-around;
      flex-direction: column;
    }

    .languages {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    &--actions {
      display: flex;
      justify-content: space-around;
      align-items: center;
      gap: 10px;

      button {
        background-color: #2f71f2 !important;
      }
    }
  }
}
</style>
