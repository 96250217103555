<template>
  <div>
    <ObiLoading v-if="isLoading" />
  </div>
</template>

<script>
import merge from 'lodash/merge'

export default {
  name: 'TenantLogin',
  components: {},
  props: {
    value: {},
    size: {
      type: Number,
    },
  },
  async mounted() {
    if (this.$route.params.pathMatch == 'auth/wifidogAuth/auth/') {
      window.location.href = 'http://apis.obifi.io/auth/wifidogAuth/auth'
    } else {
      this.$router.push({
        name: 'main-login',
        query: merge(this.$route.query, {
          tenant: this.$route.params.tenantId,
        }),
      })
    }
  },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.ad-page {
  width: 100%;
  height: 100%;
  background-color: #0842b5;
  display: flex;
  flex-flow: column;
  align-items: center;

  .ad-title {
    font-size: 3rem;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
  }

  .divider {
    border: 1px solid #275fcd;
    width: 100% !important;

    &--footer {
      position: absolute;
      bottom: 80px;
    }
  }

  .footer {
    position: fixed;
    background-color: #31405d;
    bottom: 0px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-around;

    @media (max-width: 375px) {
      justify-content: space-around;
      flex-direction: column;
    }

    .languages {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    &--actions {
      display: flex;
      justify-content: space-around;
      align-items: center;
      gap: 10px;

      button {
        background-color: #2f71f2 !important;
      }
    }
  }
}
</style>
