import Form from "@/libs/form";

const fields = ["email", "token"];

const rules = {
  email: "required|email",
  token: "sometimes",
};

export default Form.create(fields).validate(rules);
