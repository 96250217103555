import Form from "@/libs/form";

const fields = ["phone", "firstname", "lastname", "code", "rhid", "email"];

const rules = {
  phone: "required",
  firstname: "required",
  lastname: "required",
  rhid: "required",
  email: "sometimes|email",
};

export default Form.create(fields).validate(rules);
