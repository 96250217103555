import Form from "@/libs/form";

const fields = ["username", "password", "email", "phone"];

const rules = {
  username: "required",
  password: "required",
  email: "sometimes|email",
  phone: "sometimes",
};

export default Form.create(fields).validate(rules);
